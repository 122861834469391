import React from "react"
import withLocation from "../components/withLocation"
import Layout from "../components/layout"
import ResetPassword from "../components/global/resetPassword"

const RazMdp = ({ search }) => {
  const metaRobots = [
    {
      name: "robots",
      content: "noindex",
    },
  ]
  return (
    <Layout
      titleSeo="Remise à zéro du mot de passe"
      keywordsSeo={[`password`, `reset`]}
      meta={metaRobots}
    >
      <section className="pageHeader">
        <div className="pageHeaderContainer">
          <nav>
            <ul className="breadcrumb">
              <li className="breadcrumbItem">
                <a
                  href="/"
                  className="breadcrumbItemLink breadcrumbItemLinkHome"
                >
                  <i className="icon-mgc" />
                </a>
              </li>
              <li className="breadcrumbItem" key="/mot-de-passe-oublie">
                <a
                  className="breadcrumbItemLink breadcrumbItemLinkMiddle"
                  href="/raz-mdp"
                >
                  Remise à zéro du mot de passe
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <section className="pageHeader callback">
        <div>
          <h1 className="pageHeaderTitle">Remise à zéro du mot de passe</h1>
        </div>
      </section>
      <section className="container">
        <ResetPassword keyProp={search.key} />
      </section>
    </Layout>
  )
}

export default withLocation(RazMdp)
