import React from "react"
import classNames from "classnames"
import FormulaLevelsV2 from "./formulaLevelsV2"
import { EcheancierService } from "../../../services/echeancierService"
import PropTypes from "prop-types"
import informationIcon from "../../../images/icon-information.png"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const FormulaSlideV2 = ({
  f,
  handleChangeFormula,
  disabled,
  button,
  onClickButton,
  echeancierDisplay,
  knowMore,
  setKnowMore,
  echeancier,
  setEcheancier,
}) => {
  const handleKnowMoreClick = () => {
    setKnowMore(!knowMore)
  }
  const handleEcheancierClick = () => {
    setEcheancier(!echeancier)
  }

  const formatCotisationMensuelle = f => {
    return (
      <>
        {new Intl.NumberFormat("fr-FR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(f.cotisMensuelle)}
        €/mois
      </>
    )
  }

  return (
    <div
      key={"slide" + f.id}
      className={classNames("card", { choosen: !!f.conseil })}
    >
      <div className="card-header">Nous vous conseillons</div>
      <div className="card-body">
        <div className="row no-gutters formula-slider-header">
          <div className="col formule-title my-auto">{f.formule.libelle}</div>
          <div
            className="col text-right my-auto pl-2"
            key={f.numero + 1}
            onClick={() => handleChangeFormula(f.formule.id)}
          >
            {formatCotisationMensuelle(f)}
          </div>
        </div>
        <FormulaLevelsV2 formula={f} onClick={() => onClickButton(f)}/>
        <div className="row" hidden={!button}>
          <div className="col-12 text-center mt-3 mx-auto">
            <button
              type="button"
              className={classNames("buttonAel btn-formule mx-auto", {
                selected: f.selected,
                disabled: disabled,
              })}
              disabled={disabled}
              onClick={() => onClickButton(f)}
            >
              {f.selected ? "Formule choisie" : "Choisir cette formule"}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-md-right pt-3">
            <span className="know-more" onClick={handleKnowMoreClick}>
              > Plus de détails
            </span>
          </div>
        </div>
        <div className="row" hidden={!knowMore}>
          <div className="col-12 formule-presentation pt-3">
            {f.formule.presentation}
          </div>
        </div>
        <div className="row" hidden={!knowMore || !f.formule.plusFormule}>
          <div className="col-12 pt-3 plus-formule">
            Les + de la formule :
            <div dangerouslySetInnerHTML={{ __html: f.formule.plusFormule }} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-3" hidden={!knowMore}>
            <div className="d-flex align-items-center">
              <OverlayTrigger
                overlay={
                  <Tooltip id="title-tooltip" placement={"right"}>
                    <p>
                      Ces cotisations mensuelles pourront être proratisées afin de 
                      correspondre à la durée d'adhésion effective en tenant compte,
                      le cas échéant, de l'offre promotionnelle applicable à votre adhésion.
                    </p>
                  </Tooltip>
                }
              >
                <img
                  src={informationIcon}
                  className="icon-info-ael-formula"
                  alt="info icone"
                />
              </OverlayTrigger>
              <span className="know-more ml-1" onClick={handleEcheancierClick}>
                > Echéancier prévisionnel
              </span>
            </div>
          </div>
        </div>
        <div className="row" hidden={!knowMore || !echeancier}>
          <div className="col-12 formule-echeancier">
            {EcheancierService.echeancierToEcheancierDisplay(f.echeancier).map(
              ech => {
                return echeancierDisplay(ech)
              }
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FormulaSlideV2.propTypes = {
  f: PropTypes.object,
  handleChangeFormula: PropTypes.func,
  disabled: PropTypes.bool,
  button: PropTypes.bool,
  onClickButton: PropTypes.func,
  echeancierDisplay: PropTypes.func,
  knowMore: PropTypes.bool,
  echeancier: PropTypes.bool,
  setKnowMore: PropTypes.func,
  setEcheancier: PropTypes.func,
}

FormulaSlideV2.defaultProps = {
  handleChangeFormula: () => {},
  disabled: false,
  button: false,
  onClickButton: () => {},
  echeancierDisplay: () => {},
  knowMore: false,
  echeancier: false,
  setKnowMore: () => {},
  setEcheancier: () => {},
}

export default FormulaSlideV2
