import React from "react"
import PropTypes from "prop-types"
import UrlService from "../../../services/global/urlService"
import "./formulaLevelV2.css"

const FormulaLevelV2 = ({ link, level, rootClass, label, maxLevel }) => {
  let fill = []
  let empty = []
  for (let i = 0; i < level; i++) {
    fill.push(i)
  }
  for (let j = 0; j < maxLevel - level; j++) {
    empty.push(j)
  }
  const rootClassNames = `formula-level ${rootClass || ""}`
  return (
    <div className="row no-gutters">
      {link ? (
        <a href={UrlService.addSlashToEndOfUrl(link) || ""}>
          <div className={rootClassNames}>
            {fill.map(i => (
              <i className="fas fa-circle" key={i} />
            ))}
            {empty.map(i => (
              <i className="far fa-circle" key={i} />
            ))}
          </div>
        </a>
      ) : (
        <>
          <div className="col-7 my-auto level-label pr-1 pt-1 pt-lg-0">{label}</div>
          <div className="formula-level icon col text-right my-auto">
            {fill.map(i => (
              <i className="fas fa-circle" key={i} />
            ))}
            {empty.map(i => (
              <i className="far fa-circle" key={i} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

FormulaLevelV2.propTypes = {
  link: PropTypes.string,
  level: PropTypes.number,
  rootClass: PropTypes.string,
  tns: PropTypes.bool,
  maxLevel: PropTypes.number,
  label: PropTypes.string,
}

FormulaLevelV2.defaultProps = {
  link: "",
  rootClass: "",
  tns: false,
  maxLevel: 5,
}

export default FormulaLevelV2
