import * as Yup from "yup"

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{8,}/, {
      message: "format",
      excludeEmptyString: true,
    }),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "confirmation")
    .required()
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{8,}/, {
      message: "format",
      excludeEmptyString: true,
    }),
})

export default ResetPasswordSchema
