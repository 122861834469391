import React, { useState } from "react"
import { Field, Formik } from "formik"
import PropTypes from "prop-types"
import "./resetPassword.css"
import AelAuthentificationService from "../../services/aelAuthentificationService"
import classNames from "classnames"
import ResetPasswordSchema from "../../validators/forgottenPassword/resetPasswordSchema"
import FormulaSlideV2 from './../quotation/v2/formulaSlideV2';

const ResetPassword = ({ keyProp }) => {
  const [error, setError] = useState(false)
  const [success, setSucces] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false)
  
  const handleSubmit = (values, setSubmitting, setValues) => {
    const { password } = values
    AelAuthentificationService.finishResetPassword(keyProp, password).then(
      () => {
        setSucces(true)
        setError(false)
      },
      () => {
        setError(true)
        setSucces(false)
      }
    )
  }

  const values = {
    password: "",
    passwordConfirmation: "",
  }

  const canSubmit = (dirty, errors) => {
    return dirty && (!errors || !Object.keys(errors).length)
  }

  const buttonClassNames = (dirty, errors) => {
    return classNames("btn btn-primary mx-0 mx-md-2 mt-2 mt-md-0", {
      disabled: !canSubmit(dirty, errors),
    })
  }

  const isErrorPasswordFormat = (dirty, errors) => {
    return dirty && errors && errors.password && errors.password === "format"
  }

  const isErrorPasswordConfirmation = (dirty, errors) => {
    return (
      dirty &&
      errors &&
      errors.passwordConfirmation &&
      errors.passwordConfirmation === "confirmation"
    )
  }
  const togglePasswordVisibility = () => {
      let sh = passwordShown ? false:true;
      setPasswordShown(sh);
  }
  const togglePasswordConfirmVisibility = () => {
      let sh = passwordShownConfirm ? false:true;
      setPasswordShownConfirm(sh);
  }

  return (
    <Formik
      onSubmit={(values, { setValues, setSubmitting }) => {
        handleSubmit(values, setSubmitting, setValues)
      }}
      initialValues={values}
      validationSchema={ResetPasswordSchema}
      render={props => (
        <div className={"row py-5 mgc-reset-password"}>
          <div className="col-12">
            <div
              className="alert alert-success text-left"
              role="alert"
              hidden={!(success && !error)}
            >
              Votre mot de passe a bien été modifié
            </div>
            <div
              className="alert alert-danger text-left"
              role="alert"
              hidden={!(error && !success)}
            >
              Une erreur est survenue
            </div>
          </div>
          <div className="col-12">
            <form
              onSubmit={props.handleSubmit}
              className="forgotten-password justify-content-center"
            >
              <div className="form-group input-group">
                <Field
                  name={"password"}
                  className={classNames("form-control", {
                    "is-invalid": props.errors && props.errors.password,
                  })}
                  placeholder={"Mot de passe"}
                  type={passwordShown ? "text":"password"}
                />
                <div class="input-group-addon">
                <i onClick={togglePasswordVisibility} class={passwordShown ? 'fa fa-eye-slash toggle-password' : 'fa fa-eye toggle-password'}></i>
                </div>
              </div>
              <div className="form-group">
                <div
                  className={"col-12 error-msg"}
                  hidden={!isErrorPasswordFormat(props.dirty, props.errors)}
                >
                  Votre mot de passe doit être composé d’au moins 8 caractères,
                  contenant au moins 1 majuscule, 1 chiffre et 1 caractère
                  spécial.
                </div>
              </div>

              <div className="form-group input-group">
                <Field
                  name={"passwordConfirmation"}
                  className={classNames("form-control", {
                    "is-invalid":
                      props.errors && props.errors.passwordConfirmation,
                  })}
                  placeholder={"Confirmation mot de passe"}
                  type={passwordShownConfirm ? "test":"password"}
                />
                <div class="input-group-addon">
                <i onClick={togglePasswordConfirmVisibility} class={passwordShownConfirm ? 'fa fa-eye-slash toggle-password' : 'fa fa-eye toggle-password'}></i>
                </div>
              </div>
              <div className="form-group">
                <div
                  className="error-msg"
                  hidden={
                    !isErrorPasswordConfirmation(props.dirty, props.errors)
                  }
                >
                  Votre mot de passe et sa confirmation doivent être identiques.
                </div>
              </div>

              <div className="form-row">
                <button
                  type="submit"
                  className={buttonClassNames(props.dirty, props.errors)}
                  disabled={!canSubmit(props.dirty, props.errors)}
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    />
  )
}

ResetPassword.propTypes = {
  keyProp: PropTypes.string.isRequired,
}

export default ResetPassword
