export class EcheancierService {
  static echeancierToEcheancierDisplay(echeancier) {
    let echeancierDisplay = []
    let previousEcheance = null

    for (const e of echeancier) {
      let echeanceDisplay = {
        dateDebut: e.date,
        dateFin: e.date,
        libelle: e.libelle,
        montant: e.montant,
        montantRounded: Number.parseFloat(e.montant).toFixed(2)
      }
      if (
        !previousEcheance ||
        !this.isSameEcheance(previousEcheance, echeanceDisplay)
      ) {
        echeancierDisplay.push(echeanceDisplay)
      } else {
        previousEcheance.dateFin = e.date
      }
      previousEcheance = echeancierDisplay[echeancierDisplay.length - 1]
    }
    return echeancierDisplay
  }

  static isSameAmount(previousEcheance, echeance) {
    return previousEcheance.montantRounded === echeance.montantRounded
  }
  static isSameLabel(previousEcheance, echeance) {
    return (
      previousEcheance.libelle === echeance.libelle ||
      (!echeance.libelle && !echeance.libelle)
    )
  }

  static isSameEcheance(previousEcheance, echeance) {
    return (
      this.isSameAmount(previousEcheance, echeance) &&
      this.isSameLabel(previousEcheance, echeance)
    )
  }
}
